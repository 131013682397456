.userList{
    flex: 4;
    height: calc(100vh - 70px);
}
.userListDataGrid{
    flex: 4;
    height: calc(100vh - 150px);
    margin-left: 10px;
    margin-right: 10px;
    /*width: 80%;*/
}
.userListUser{
    display: flex;
    align-items: center;
}
.userListAction{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 200px;
}
.userListImg{
    width:32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
.userListEdit{
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    margin-right: 20px;
    cursor: pointer;
}
.userListDelete{
    color: red !important;
    cursor: pointer;
}