/* FloatingButton.css */
.floating-button {
    position: fixed;
    bottom: 100px;
    right: -20px;
    width: 60px;
    height: 30px;
    background-color: #0082ff;
    border-radius: 10%;
    transition: width 0.3s, height 0.3s;
    overflow: hidden;
    z-index: 2000;
}

.floating-button.hovered {
    background-color: #60b2ff;
}

.icon-container {
    display: flex;
    justify-content: left;
    align-items: center;
    width: 100%;
    height: 100%;
}

.plus-icon {
    padding: 10px;
    font-size: 36px;
    color: white;
    cursor: pointer;
}
