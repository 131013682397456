.property{
    flex: 4;
    padding: 10px;
}
.userContainer{
    display:flex;
}
.userAddButton{
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}
.userContainer{
    display:flex;
    margin-top: 20px;

}
/*.propertyFeaturedItemBox {*/
/*    flex: 1;*/
/*    margin-right: 10px;*/
/*    padding: 10px;*/
/*    border-radius: 5px;*/
/*    cursor: pointer;*/
/*    -webkit-box-shadow: 5px 5px 9px 2px rgba(0, 0, 0, 0.2);*/
/*    box-shadow: 5px 5px 9px 2px rgba(0, 0, 0, 0.2);*/
/*}*/
/*.propertyShowDetails{*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*    !*padding: 10px;*!*/
/*    !*-webkit-box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);*!*/
/*    !*box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);*!*/
/*}*/

.propertyShowDetails {
    display: flex;
    /*flex-direction: column;*/
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
}
.propertyFeaturedItemBox {
    padding: 15px;
    border-radius: 10px;
    background-color: white;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.userUpdate{
    flex:2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    margin-left: 20px;
}
.userShowTop{
    display: flex;
    align-items: center;
}

.userShowImg{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.userShowTopTitle{
    display:flex;
    flex-direction: column;
    margin-left: 20px;


}
.userShowUsername{
    font-weight: 600;
}
.userShowUserTitle{
    font-weight: 300;
}

.userShowBottom{
    margin-top: 20px;
}

.userShowTitle{
    font-size: 14px;
    font-weight: 600;
    color: rgb(175,170,170);
}

.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.userShowIcon{
        font-size: 16px !important;
}

.userShowInfoTitle{
    margin-left: 10px;
}

.userUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}
.userUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}

.userUpdateInput{
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid grey;
}
.userUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 20px;
}
.userUpdateIcon{
    cursor: pointer;
}

.userUpdateUpload{
    display: flex;
    align-items: center;
}

.userUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.userUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
}
.agreementFiles{
    display: flex;
    align-items: center;
    width: 100%;
}

#fullScreenBlurrBackground {
    -webkit-filter: blur(5px);
    backdrop-filter: blur(10px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    /*background-color: #ccc;*/
}

@media only screen and (max-width:700px)  {
    .propertyShowDetails{
        flex-direction: column;
        justify-content: space-between;
    }
    .propertyFeaturedItemBox {
        margin-bottom: 10px;
        margin-right: 0;
    }
}