/* FormPage.css */

.container {
    margin: 0 auto;
    padding: 20px;
    background-color: #F7F9FC;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.form {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.form-row {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.form-group {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    min-width: 250px;
}

label {
    font-size: 14px;
    color: #555;
    font-weight: bold;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

input.light-blue {
    padding: 10px 15px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

input.light-blue:focus {
    border-color: #3799d6;
    box-shadow: 0 0 5px rgba(55, 153, 214, 0.5);
}

.error-message {
    color: #d9534f;
    font-size: 12px;
    font-style: italic;
}

h3.section-title {
    font-size: 20px;
    color: #333;
    margin: 20px 0 10px;
    border-bottom: 2px solid #3799d6;
    padding-bottom: 5px;
    text-transform: uppercase;
    letter-spacing: 1px;
}

button.button.primary {
    padding: 12px 20px;
    background-color: #38b973;
    color: white;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    width: 100%;
    max-width: 200px;
    margin: 0 auto;
}

button.button.primary:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

button.button.primary:hover:not(:disabled) {
    background-color: #2ea860;
    transform: translateY(-2px);
}

button.button.primary:active:not(:disabled) {
    transform: translateY(1px);
}

/*.form-group input::placeholder {*/
/*    font-style: italic;*/
/*    color: #aaa;*/
/*}*/
