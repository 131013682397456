@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

@media screen and (min-width: 860px)  {
  .register .col-2 img{
    display: initial;
  }
}

.cont{
  display: flex;
}
.App{
  flex-grow: 1;
}

@media only screen and (max-width:700px)  {
  .cont{
    width: 100vw;
  }
}


/*  Test notifications*/

