.error404{
    display: block;
    z-index: 9999;
    width: 95vw;
    height: 95vh;
    background-size: contain;
    background-repeat: no-repeat no-repeat;
    background-position: center center;
    /*// backgroundColor: "black",*/
}
#fullScreenBlurrBackground {
    -webkit-filter: blur(5px);
    backdrop-filter: blur(10px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    /*background-color: #ccc;*/
}