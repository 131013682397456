body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /*height: 120%;*/
    zoom: 100%;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.error {
    color: #fc8181;
    font-size: 0.75rem;
    text-align: left;
    margin-top: 0.25rem;
}

input.input-error,
select.input-error {
    border-color: #fc8181;
}

button:disabled {
    opacity: 0.35;
}

input:focus,
select:focus {
    border-color: #4299e1;
}

input::placeholder,
select::placeholder {
    color: #a0aec0;
}

input[type="checkbox"] {
    width: fit-content;
    margin-right: 0.5rem;
    transform: scale(1.25);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield; /* Firefox */
}


/* Scrollbar for modern browsers */
::-webkit-scrollbar {
    width: 10px; /* Width of the scrollbar */
    height: 10px; /* Height of the horizontal scrollbar */
}

/* Track of the scrollbar */
::-webkit-scrollbar-track {
    background: #f3f7fa; /* Light background for the track */
    border-radius: 10px;
}

/* Thumb (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
    background: #e1c2bc; /* Main color of the scrollbar */
    border-radius: 10px;
    border: 2px solid #f3f7fa; /* Adds padding for a cleaner look */
}

/* Thumb hover effect */
::-webkit-scrollbar-thumb:hover {
    background: #0066cc; /* Darker blue on hover */
}

/* For Firefox */
* {
    scrollbar-width: thin; /* Makes the scrollbar thinner */
    scrollbar-color: #e1c2bc #f3f7fa; /* Thumb color and track color */
}

/* Optional: Hide scrollbar on specific elements */
.hidden-scrollbar {
    scrollbar-width: none; /* No scrollbar in Firefox */
    -ms-overflow-style: none; /* No scrollbar in IE 10+ */
}

.hidden-scrollbar::-webkit-scrollbar {
    display: none; /* No scrollbar in WebKit browsers */
}
