.userList{
    flex: 4;
}
.userListUser{
    display: flex;
    align-items: center;
}
.userListImg{
    width:32px;
    height: 32px;
    border-radius: 50%;
    object-fit: cover;
    margin-right: 10px;
}
.userListEdit{
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    background-color: #3bb077;
    color: white;
    margin: 20px;
    cursor: pointer;
}
.userListDelete{
    color: red !important;
    cursor: pointer;
}
.MuiDataGrid-colCellTitle {
    display: block; 
    text-align:center; 
    width: 100%; 
}

.MuiDataGrid-cell {
    display: block;
    position: relative;
    text-align: center;
}