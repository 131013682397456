@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* > * {
    margin: 0;
}

.login {
    flex: 4;
    height: 100vh;
}

.fullPage {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    /*backgroundColor: #E4E6E8;*/
    /*background: linear-gradient(#FFEDCB, #3D4856);*/
    /*background: linear-gradient(#FFEDCB, #897456);*/
    align-items: center;
}

.register {
    /*max-width: 978px;*/
    height: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    text-align: center;
    background-color: white;
    margin: 0 auto;
}


.register span {
    color: black;
}

#form {
    max-width: 320px;
    width: 100vw;
    margin: 2em auto;
}

#form > input, .btn {
    border: 1px solid #e9ecef;
    padding: .9em 1em;
}

#form > input:focus {
    outline: none;
}

#form > .btn {
    background-color: #003049;
    color: #e9ecef;
    font-size: 1em;
    cursor: pointer;
}

#form > span {
    font-size: 0.8em;
}

#form > span > a {
    text-decoration: none;
}


.flex {
    display: flex;
    gap: 1em;
}

.flex-col {
    flex-direction: column;
}

.register .col-1-auth {
    overflow: auto;
    flex: 1;
    padding-left: 3em;
    padding-right: 3em;
    margin-top: 2em;
    margin-bottom: 2em;
    zoom: 80%;
}

.register .col-2-auth {
    flex: 0.8;
    width: 100%;
}

.register .col-2-auth img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: initial;
    background-repeat: no-repeat;
    background-position: center;
    align-self: flex-end;
    border-radius: 40px 0 0 40px;
}

@media screen and (max-width: 700px) {
    .register .col-2-auth img {
        display: none;
    }

    .register .col-2-auth {
        width: 0;
    }
    .register .col-2-auth {
        flex: 0;
        width: 0;
    }
}

.col-1-auth {
    position: relative; /* Ensure dropdown aligns relative to the input */
    overflow: visible; /* Allow dropdown to be displayed fully */
}

/*  Test notifications*/

