.user{
    flex: 6;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    /*height: 100vh;*/
    overflow: auto;
}
.sidebarListItemDetails {
    text-decoration: none;
    color: white;
    padding: 2px;
    margin-bottom: 2px;
    cursor: pointer;
    display: flex;
    font-size: 13px;
    align-items: center;
    border-radius: 5px;
}

.userTitleContainer{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.userAddButton{
    width: 80px;
    border: none;
    padding: 5px;
    background-color: teal;
    border-radius: 5px;
    cursor: pointer;
    color: white;
    font-size: 16px;
}
.userShowDetails{
    display: flex;
    justify-content: space-between;
    /*padding: 10px;*/
    /*-webkit-box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);*/
    /*box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);*/
}
.featuredItemBox {
    flex: 1;
    margin-right: 10px;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
    -webkit-box-shadow: 5px 5px 9px 2px rgba(0, 0, 0, 0.2);
    box-shadow: 5px 5px 9px 2px rgba(0, 0, 0, 0.2);
}
.tableInfo{
    display: flex;
    justify-content: space-evenly;
    padding: 20px;
    margin-bottom: 10px;
    -webkit-box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0 0 15px -10px rgba(0, 0, 0, 0.75);

}
.tableInfoTitle{
    width: 40%;
    color: #111;
    font-family: 'Helvetica Neue', sans-serif;
    font-size: 20px;
    font-weight: bold;
    letter-spacing: -1px; line-height: 1;
    text-align: center;
}
.userUpdate{
    flex:2;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
    margin-left: 20px;
}
.userShowTop{
    display: flex;
    align-items: center;
}
.userShowTopTitle{
    display:flex;
    flex-direction: column;
    margin-left: 20px;


}
.userShowUsername{
    font-weight: 600;
}
.userShowUserTitle{
    font-weight: 300;
}

.userShowBottom{
    margin-top: 20px;
}

.userShowTitle{
    font-size: 14px;
    font-weight: 600;
    color: rgb(175,170,170);
}

.userShowInfo{
    display: flex;
    align-items: center;
    margin: 20px 0px;
    color: #444;
}

.userShowIcon{
        font-size: 16px !important;
}

.userShowInfoTitle{
    margin-left: 10px;
}

.userUpdateTitle{
    font-size: 24px;
    font-weight: 600;
}
.userUpdateForm{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}
.userUpdateItem{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.userUpdateItem>label{
    margin-bottom: 5px;
    font-size: 14px;
}

.userUpdateInput{
    border: none;
    width: 250px;
    height: 30px;
    border-bottom: 1px solid grey;
}
.userUpdateImg{
    width: 100px;
    height: 100px;
    border-radius: 5px;
    object-fit: cover;
    margin-right: 20px;
}
.userUpdateIcon{
    cursor: pointer;
}

.userUpdateUpload{
    display: flex;
    align-items: center;
}

.userUpdateRight{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.userUpdateButton{
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
}
.agreementFiles{
    display: flex;
    align-items: center;
    width: 100%;
}
@media only screen and (max-width:700px)  {
    .userShowDetails{
       flex-direction: column;
        justify-content: space-between;
    }
    .featuredItemBox {
        margin-bottom: 10px;
        margin-right: 0;
    }
}