.sidebarLogo {
    text-align: center;
    margin: 20px 0;
}

.sidebarLogo img {
    width: 150px;
    height: auto;
}

.MenuItem {
    font-size: 1rem;
    color: #4a4a4a;
}

.MenuItem.active {
    background-color: #3d8b84;
    color: #ffffff;
}

.MenuItem:hover {
    background-color: #67bc89;
    color: #ffffff;
}
