/* settings.css */
.settings-container {
    display: flex;
    height: calc(100vh - 100px);
    padding: 20px;
}

.sidebar {
    flex: 20%;
    padding: 20px;
    background-color: #f4f6f8;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.settings-title {
    font-weight: bold;
    margin-bottom: 10px;
}

.sidebar-items {
    display: flex;
    flex-direction: column;
}

.sidebar-item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.sidebar-item:hover {
    background-color: #f0f0f0;
}

.sidebar-item.active {
    background-color: #00796b;
    color: #fff;
}

.sidebar-icon {
    margin-right: 10px;
}

.sidebar-item-text {
    font-size: 16px;
    font-weight: 500;
}

.content {
    flex: 80%;
    padding: 20px;
    box-shadow: 5px 5px 9px 2px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    background-color: #fff;
}

.backdrop {
    z-index: 1;
}

@media screen and (max-width: 768px) {
    .settings-container {
        flex-direction: column;
    }

    .sidebar {
        flex: 100%;
        margin-bottom: 20px;
    }

    .content {
        flex: 100%;
    }
}
